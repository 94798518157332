/* montserrat-200 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/montserrat-v25-latin-200.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/montserrat-v25-latin-200.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/montserrat-v25-latin-200.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/montserrat-v25-latin-200.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/montserrat-v25-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/montserrat-v25-latin-200.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/montserrat-v25-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/montserrat-v25-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/montserrat-v25-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/montserrat-v25-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/montserrat-v25-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/montserrat-v25-latin-300.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/montserrat-v25-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/montserrat-v25-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/montserrat-v25-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/montserrat-v25-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/montserrat-v25-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/montserrat-v25-latin-regular.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/montserrat-v25-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/montserrat-v25-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/montserrat-v25-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/montserrat-v25-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/montserrat-v25-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/montserrat-v25-latin-700.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/lato-v23-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/lato-v23-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/lato-v23-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/lato-v23-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/lato-v23-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/lato-v23-latin-regular.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/lato-v23-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/lato-v23-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/lato-v23-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/lato-v23-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/lato-v23-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/lato-v23-latin-700.svg#Lato")
      format("svg"); /* Legacy iOS */
}

::-webkit-scrollbar {
  display: none;
}

body,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  width: 100vw;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #091154;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-align-right {
  text-align: right;
}

.p-bold {
  font-weight: bold;
}

.customA:hover {
  cursor: pointer;
  text-decoration: underline;
}

.flex {
  flex: 1;
}

textarea:focus,
input:focus,
div:focus {
  outline: none;
}

.positive {
  color: #2ecc71;
}

.negative {
  color: #e74c3c;
}

.submit-button {
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: #091154;
  color: white;
  font-weight: bold;
  border: none;
  box-shadow: 0 4px 0 0 #020735, 0 6px 6px -1px rgba(0, 0, 0, 0.48);
  border-radius: 5px;
  margin-bottom: 10px;
}
.submit-button:hover {
  cursor: pointer;
  box-shadow: 0 4px 0 0 #02041b, 0 6px 6px -1px rgba(0, 0, 0, 0.48);
}
.submit-button-pos {
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: #2ecc71;
  color: white;
  font-weight: bold;
  border: none;
  box-shadow: 0 4px 0 0 #27ae60, 0 6px 6px -1px rgba(0, 0, 0, 0.48);
  border-radius: 5px;
  margin-bottom: 10px;
}
.submit-button-pos:hover {
  cursor: pointer;
  box-shadow: 0 4px 0 0 #20904f, 0 6px 6px -1px rgba(0, 0, 0, 0.48);
}
.submit-button-neg {
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: #e74c3c;
  color: white;
  font-weight: bold;
  border: none;
  box-shadow: 0 4px 0 0 #d35400, 0 6px 6px -1px rgba(0, 0, 0, 0.48);
  border-radius: 5px;
  margin-bottom: 10px;
}
.submit-button-neg:hover {
  cursor: pointer;
  box-shadow: 0 4px 0 0 #c0392b, 0 6px 6px -1px rgba(0, 0, 0, 0.48);
}
.submit-button-warn {
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f1c40f;
  color: white;
  font-weight: bold;
  border: none;
  box-shadow: 0 4px 0 0 #fbc531, 0 6px 6px -1px rgba(0, 0, 0, 0.48);
  border-radius: 5px;
  margin-bottom: 10px;
}
.submit-button-warn:hover {
  cursor: pointer;
  box-shadow: 0 4px 0 0 #e1b12c, 0 6px 6px -1px rgba(0, 0, 0, 0.48);
}

/* Site specific */
.page-wrapper {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
}

.opacity-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 60vh;
  background-color: #091154;
  opacity: 0.45;
  z-index: 2;
  margin-top: 103px;
}

.title-wrapper {
  background-position: bottom;
  background-size: cover;
  margin-top: 103px;
  position: relative;
  height: 60vh;
  margin-bottom: 70px;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 600;
  font-size: 70px;
  z-index: 6;
}

.content-wrapper {
  padding-left: 10vw;
  padding-right: 10vw;
}

.content-title {
  font-weight: 600;
  font-size: 35px;
  color: #091154;
  margin-top: 70px;
  margin-bottom: 25px;
}

.content-subtitle {
  font-weight: 400;
  font-size: 20px;
  color: #091154;
  margin-top: 50px;
  margin-bottom: 25px;
}

.content-text {
  color: black;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

@media only screen and (max-width: 500px) {
  .opacity-wrapper {
    margin-top: 71px;
    height: 30vh;
  }

  .title-wrapper {
    margin-top: 71px;
    height: 30vh;
  }

  .title {
    font-weight: 600;
    font-size: 27px;
    width: 100vw;
    text-align: center;
  }

  .content-text {
    font-size: 17px;
    line-height: 25px;
  }

  .content-title {
    font-size: 28px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 850px) and (orientation: landscape) {
  .title-wrapper {
    background-repeat: cover;
  }
}
